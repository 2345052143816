var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('winblock',{scopedSlots:_vm._u([{key:"win-title-left",fn:function(){return [_vm._v("Пользовательские JSON пакеты")]},proxy:true},{key:"win-title-right",fn:function(){return [_c('v-text-field',{attrs:{"hide-details":"","single-line":"","disabled":_vm.btn_vis}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.btn_vis=!_vm.btn_vis}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"calories","loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("My TEST Data")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","dark":"","elevation":"1"},on:{"click":function($event){return _vm.reloadItems()}}},[_vm._v(" Refresh ")]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"elevation":"1","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Item ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-textarea',{attrs:{"height":"100%"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Данны пользователя ")])]},proxy:true}]),model:{value:(_vm.editedItem.userJSON),callback:function ($$v) {_vm.$set(_vm.editedItem, "userJSON", $$v)},expression:"editedItem.userJSON"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.jsonUSER",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"primary","outlined":"","elevation":"0"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" JSON пользователя ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.reloadItems}},[_vm._v(" Reset ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }