<template>
  <v-card>
    <v-toolbar
        style="border-bottom: 2px solid #ececec;"
        color="#FDFDFD"
        flat
        dense
    >

      <v-toolbar-title class="grey--text text--lighten-2">
        <slot name="win-title-left"/>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <slot name="win-title-right"/>
      <!--
            <v-text-field
                hide-details
                single-line
            ></v-text-field>

            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
      -->
      <!--
            <v-btn icon>
              <v-icon class="grey--text text--lighten-2">mdi-dots-vertical</v-icon>
            </v-btn>
      -->
    </v-toolbar>
    <v-container min-height="300" min-width="300" fluid>
<!--      <slot name="win-body"></slot>-->
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "winblock",
  components: {}
}
</script>

<style scoped>
.v-toolbar__content {

}

.v-card {
  margin: 5px;
}
</style>