<template>
  <winblock>
    <template #win-title-left>Пользовательские JSON пакеты</template>
    <template #win-title-right>
      <v-text-field
          hide-details
          single-line
          :disabled="btn_vis"
      ></v-text-field>
      <v-btn icon @click="btn_vis=!btn_vis">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>

    <v-data-table
        :headers="headers"
        :items="desserts"
        sort-by="calories"
        :loading="loadingTable"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>My TEST Data</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              dark
              class="mb-2 mr-2"
              elevation="1"
              @click="reloadItems()"
          >
            Refresh
          </v-btn>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  elevation="1"
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-textarea
                      height="100%"
                      v-model="editedItem.userJSON"
                  >
                    <template v-slot:label>
                      <div>
                        Данны пользователя
                      </div>
                    </template>

                  </v-textarea>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.jsonUSER="{ item }">

        <v-btn x-small color="primary" outlined elevation="0" @click="editItem(item)">
          JSON пользователя
        </v-btn>

      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="reloadItems"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </winblock>
</template>

<script>

// import url from "@/views/test/project-test/url";
import winblock from "@/site/modules/test/winblock";


export default {
  components: {winblock},
  data: () => ({
    btn_vis: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'id',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {text: 'Время', value: 'created'},
      {text: 'Пакеты', value: 'jsonUSER', sortable: false},
      {text: 'Действия', value: 'actions', sortable: false},

    ],
    desserts: [],
    key: '',
    loadingTable: true,
    editedIndex: -1,
    editedItem: {
      t1: '',
      c1: '',
    },
    defaultItem: {
      t1: '',
      c1: '',
    },
  }),
  mounted: function () {
    this.$bus.on("test.key_updated", this.keyUpdated);
  },
  beforeDestroy: function () {
    this.$bus.off("test.key_updated", this.keyUpdated);
  },
  // created() {
  //   this.initialize()
  // },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    initialize() {

    },
    reloadItems() {
      this.$axios.get(this.$url.test_user_test1, {params: {key: this.key}})
          .then(response => {
            this.desserts = response.data
            this.loadingTable = false
          })
    },
    keyUpdated(key) {
      this.key = key
      this.reloadItems()
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.userJSON = JSON.stringify(item.userJSON, null, '\t')
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$axios.delete(this.$url.test_user_test1 + `${this.editedItem.id}/`, {params: {key: this.key}})
          .then(response => {
            response
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
          })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        console.log("this.editedIndex1",this.editedIndex)
        let aaa = {'userJSON': JSON.parse(this.editedItem.userJSON)}
        this.$axios.patch(this.$url.test_user_test1 + `${this.editedItem.id}/`, aaa , {params: {key: this.key}})
            .then(response => { response
              Object.assign(this.desserts[this.editedIndex], response.data)
              this.close()
            })

      } else {
        this.$axios.post(this.$url.test_user_test1, JSON.parse(this.editedItem.userJSON), {params: {key: this.key}})
            .then(response => {
              this.editedItem = response.data
              this.desserts.unshift(this.editedItem)
              this.close()
            })
      }
    },
  },
}
</script>